import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logoOG.jpg";
import { FiMail } from "react-icons/fi";
import { Button, Box, Modal, } from "@mui/material";
import { useInView } from "react-intersection-observer";
import {
  ButtonContainer,
  HeroButton,
  ButtonWrapper,
} from "../LiveChat/HeroStyles";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [showModal, setShowModal] = useState(false);
  const dragConstraints = { top: 0, bottom: 0, right: 0, left: 0 };

  const toggleModal = () => {
    if (!showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    setShowModal(!showModal);
  };
  

  const variants = {
    hover: {
      y: 15,
      transition: {
        yoyo: Infinity,
        duration: 0.6,
      },
    },
  };
  const { ref, inView } = useInView({
    rootMargin: "-100px",
  });

  useEffect(() => {
    console.log(inView);
  }, [inView]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "30%",
    left: "30%",
    // transform: 'translate(-50%, -50%)',
    width: "35%",
    height: "65vh",
    // bgcolor: 'background.paper',
    background: "linear-gradient(150deg, #c99fff -20%, #4a4eff)",
    border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <nav className="navbar">
        <div className="container flex_space">
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : " fas fa-bars"}></i>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
              <Link to="/" onClick={closeMobileMenu} style={{ color: "white" }}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={closeMobileMenu}>
                About Us
              </Link>
            </li>            
            <li>
              <Link to="/services" onClick={closeMobileMenu}>
                Services
              </Link>
            </li>
{/* 
            <li>
              <Link to="/commercial" onClick={closeMobileMenu}>
                Book Now
              </Link>
            </li> */}
            {/* <li>
              <Link to="/carwash" onClick={closeMobileMenu}>
                CarWash
              </Link>
            </li> */}
            {/* <li>
              <Link to='/blog' onClick={closeMobileMenu}>
                Blog
              </Link>
            </li> */}
            {/* <li>
              <Link to="/testimonial" onClick={closeMobileMenu}>
                Testimonial
              </Link>
            </li> */}
            <li>
              <Link to="/contact" onClick={closeMobileMenu}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to='/contact'>
            <Button
                    style={{ color: "#fff", fontWeight: 700 }}
                    // onClick={handleOpen}
                  >
                    Book Now
                  </Button></Link>
                  {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <h2>Get an Instant Quote</h2>
                      <span> Search and Book Hotel</span>

                      <form>
                        <input type="text" placeholder="Seacrh City" />
                        <div className="flex_space">
                          <input type="date" placeholder="Check In" />
                          <input type="date" placeholder="Check Out" />
                        </div>
                        <div className="flex_space">
                          <input type="number" placeholder="Adult(s)(18+)" />
                          <input type="number" placeholder="Children(0- 17)" />
                        </div>
                        <input type="number" placeholder="Rooms" />
                        <input
                          type="Submit"
                          value="Search"
                          className="submit"
                        />
                      </form>
                    </Box>
                  </Modal> */}
            </li>
          </ul>

          {/* <div className='login-area flex'>
            <li>
              <Link to='/sign-in'>
                <i class='far fa-chevron-right'></i>Sign in
              </Link>
            </li>
            <li>
              <Link to='/register'>
                <i class='far fa-chevron-right'></i>Register
              </Link>
            </li>
          </div> */}
        </div>
      </nav>

      <header className="header">
        <div className="container flex_space">
          <div className="logo">
            <img style={{ height: "10vh" }} src={Logo} alt="" />
          </div>

          <div className="contact flex_space ">
            <div className="box flex_space">
              <div className="icons">
                <i
                  class="fal fa-clock"
                  style={{
                    color: "linear-gradient(150deg, #c99fff -20%, #4a4eff)",
                  }}
                ></i>
              </div>
              <div className="text">
                <h4>Working Hours</h4>
                <Link to="/contact">Sun - Fri: 08:00 - 17:30</Link> <br />
                <Link to="/contact">Sat: closed</Link>
              </div>
            </div>
            <div className="box flex_space">
              <div className="icons">
                <i class="fas fa-phone-volume"></i>
              </div>
              <div className="text">
                <h4>Call Us</h4>
                <Link to="/contact">+27 68 685 3498</Link>
              </div>
            </div>
            <div className="box flex_space">
              <div className="icons">
                <i class="far fa-envelope"></i>
              </div>
              <div className="text">
                <h4>Mail Us</h4>
                <a href="mailto:info@thecia.co.za">info@thecia.co.za</a>
              </div>
            </div>

            {/* <div className="box flex_space">
              <ButtonContainer ref={ref}>
                <ButtonWrapper>
                  <HeroButton
                    onClick={toggleModal}
                    className={inView ? "" : "corner"}
                  >
                    {inView ? (
                      <> Chat with us</>
                    ) : (
                      <FiMail color="white" size="2.3rem" />
                    )}
                  </HeroButton>
                </ButtonWrapper>
              </ButtonContainer>    
            </div> */}
          </div>
        </div>
      </header>
      {/* <Modal showModal={showModal} toggleModal={toggleModal} /> */}
    </>
  );
};

export default Navbar;
