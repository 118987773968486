import React from "react"
import AboutCard from "../About/AboutCard"
import { Link } from "react-router-dom"

const About = () => {
  return (
    <>
      <section className='homeabout'>
        <div className='container top'>
          <AboutCard />
          <Link to="/about" className="btn" style={{color: '#000000'}}>
            Explore more
          </Link>
        </div>
      </section>
    </>
  )
}

export default About
