import React from "react";
import "./App.css";
import Navbar from "./Common/Navbar/Navbar";
import About from "./Components/About/About";
import Gallery from "./Components/gallery/Gallery";
import Home from "./Components/pages/Home";
import Destinations2 from "./Components/Destinations2/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SinglePage from "./SinglePage/SinglePage";
import SinglePage2 from "./SinglePage2/SinglePage";

/*-------------blog------------ */
import Footer from "./Common/footer/Footer";
import Blog from "./Components/Blog/Blog";
import BlogSingle from "./Components/Blog/blog-single-page/BlogSingle";
import Contact from "./Components/Contact/Contact";
import Testimonial from "./Components/Testimonial/Testimonial";
import Service from "./Components/Destinations2/Service";
import houseCleaning from "./Components/products/houseCleaning";
import officeCleaning from "./Components/products2/officeCleaning";
/*-------------blog------------ */

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/services" exact component={Destinations2} />
          <Route path="/singlepage/:id" exact component={SinglePage} />
          <Route path="/singlepage2/:id" exact component={SinglePage2} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blogsingle/:id" exact component={BlogSingle} />
          <Route path="/testimonial" exact component={Testimonial} />
          <Route path="/services" exact component={Service} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/houseCleaning" exact component={houseCleaning} />
          <Route path="/officeCleaning" exact component={officeCleaning} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
