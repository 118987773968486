import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/logo.png";
import "./VideoStyles.css";
import "../Navbar/Navbar.css";
import Typed from "react-typed";
import spaceVideo from "../../Assets/Img/HeadTitle/pexels-tima-miroshnichenko-6195520.mp4";

const Video = () => {
  return (
    <div className="hero">
      <video autoPlay loop muted id="video">
        <source src={spaceVideo} type="video/mp4" />
      </video>

      <div className="content">
        <p>WELCOME TO</p>
        <h1>
          <b> The CIA</b>
        </h1>
        <p>YOUR GATEWAY TO: </p>
        <div className="content_2">
          <Typed
            style={{
              color: "#00df9a",
              fontSize: "3rem",
              textTransform: "uppercase",
              marginBottom: " 1rem",
              fontWeight: "bold",
            }}
            strings={[
              "Save Time & Increased Productivity!",
              "Welcoming Environment!",
              "Better sleep!",
              "Healthier and Happy family!",
              "Extinction of Pests!",
              "A scented Utopia!",
              "Save Money & Energy!",
              "Inspired Confidence!",
              "Less worry & Stress!",
              "Spend more time with family!",
            ]}
            typeSpeed={100}
            backSpeed={100}
            loop
          />
        </div>
        <p>Leaving behind no trace or evidence of dirt!</p>

        <br />
        <br />

        <div>
          <Link to="/houseCleaning" className="btn">
            <b>House Cleaning</b>
          </Link>
          <Link to="/officeCleaning" className="btn btn-light">
            <b>Office Cleaning</b>
          </Link>
          <a
            href="https://starwoodandironcrafts.co.za"
            target="_blank"
            className="btn"
          >
            <b>Handyman Service</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Video;
