import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import TeamCard from "./TeamCard.js";
// import pic1 from "../../Assets/Img/user-1.jpg";
// import pic2 from "../../Assets/Img/man.png";
import {
  fastFoodProducts,
  riceMenuProducts,
  pizzaProducts,

} from "./TeamData.js";
import "./Team.css";
import "../../gallery/Gallery.css";
import { TbBrandLinkedin } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";

const Team = () => {
  const [filter, setFilter] = useState("FAST-FOOD");
  const [products, setProducts] = useState(riceMenuProducts);

  useEffect(() => {
    if (filter == "RICE-MENU") {
      setProducts(riceMenuProducts);
    }
    if (filter == "FAST-FOOD") {
      setProducts(fastFoodProducts);
    }
    if (filter == "PIZZA") {
      setProducts(pizzaProducts);
    }
    // if (filter == "DESSERT") {
    //   setProducts(dessertProducts);
    // }
    // if (filter == "COFFEE") {
    //   setProducts(coffeeProducts);
    // }
  }, [filter]);

  const style = {
    color: "#ffffff",
    marginRight: "1rem",
    background: "linear-gradient(150deg, #c99fff -20%, #4a4eff)",
    width: "30px",
    height: "30px",
    lineHeight: "40px",
    textAlign: "center",
    border: "1px solid #e7e3e3",
    borderRadius: "5px",
  };

  return (
    <section>
      <div className="container-team">
        <div>
          <Col lg="12" className="text-center mb-4">
            <h1 className="menu_title_7">Recurring Packages</h1>
          </Col>
          <br />
          <br />
          {/* Images */}
          <Col lg="12" className="container-team">
            <div
              className={`filter-btn_7 ${
                filter == "FAST-FOOD" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("FAST-FOOD")}
            >
              <h3 style={{ textAlign: "center",display:'flex', alignItems:'center', justifyCOntent:'center', margin:'15px' }}>Weekly</h3>
              <div className="icon"></div>
            </div>
            <div
              className={`filter-btn_7 ${
                filter == "RICE-MENU" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("RICE-MENU")}
            >
              <h3 style={{ textAlign: "center", display:'flex', alignItems:'center', justifyCOntent:'center', margin:'15px' }}>Bi-weekly</h3>
            </div>
            <div
              className={`filter-btn_7 ${
                filter == "PIZZA" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("PIZZA")}
            >
              <h3 style={{ textAlign: "center",display:'flex', alignItems:'center', justifyCOntent:'center', margin:'15px' }}>Monthly</h3>
            </div>
            {/* <div
              className={`filter-btn_7 ${
                filter == "DESSERT" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("DESSERT")}
            >
              <img className="img" src={pic2} alt="" />
              <h3 style={{ textAlign: "center" }}>Vacant</h3>
              <h5 style={{ textAlign: "center" }}>Founder</h5>
              <div className="icon" style={{ gap: "10px" }}>
                <i className="fab fa-linkedin"></i>
                <i className="fa fa-envelope"></i>
              </div>
            </div> */}
            {/* <div
              className={`filter-btn_7 ${
                filter == "COFFEE" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("COFFEE")}
            >
              <img className="img" src={pic2} alt="" />
              <h3 style={{ textAlign: "center" }}>Vacant</h3>
              <h5 style={{ textAlign: "center" }}></h5>
              <div className="icon" style={{ gap: "10px" }}>
                <i className="fab fa-linkedin"></i>
                <i className="fa fa-envelope"></i>
              </div>
            </div> */}

            {/* Image end */}
          </Col>
          <br />

          {products.map((item) => (
            <Col
              lg="3"
              key={item.id}
              className="mb-4"
              style={{ marginTop: "25px" }}
            >
              {" "}
              <TeamCard item={item} />
            </Col>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
