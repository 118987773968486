import React from "react";
import { Link } from "react-router-dom";
import dest1 from "../../../Assets/Img/Commercial.jpg";
import dest2 from "../../../Assets/Images/dest-2.jpg";
import dest3 from "../../../Assets/Img/Car Wash.jpg";
import dest4 from "../../../Assets/Images/dest-4.jpg";
import dest5 from "../../../Assets/Images/dest-5.jpg";
import dest6 from "../../../Assets/Images/dest-6.jpg";
import Para1 from "../../../Assets/Images/singlepage/single-1.jpg";
import Para2 from "../../../Assets/Images/singlepage/single-2.jpg";

const Sdata = [
  {
    id: 11,
    title: <Link to="/commercial" className="primary-btn">House Cleaning</Link>,
    image: [dest1],
    
  },
  {
    id: 12,
    title: <Link to="/residential" className="primary-btn">Office Cleaning</Link>,
    image: [dest2],
    
  },
  {
    id: 13,
    title: <Link to="/carwash" className="primary-btn">Specialized Cleaning</Link>,
    image: [dest3],
    
  },
  // {
  //   id: 14,
  //   title: "Post-Construction",
  //   image: [dest4],
  //   desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   sidepara: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  //   paraImage_one: [Para1],
  //   paraImage_two: [Para2],
  // },
  // {
  //   id: 15,
  //   title: "Day Porter",
  //   image: [dest5],
  //   desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   sidepara: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  //   paraImage_one: [Para1],
  //   paraImage_two: [Para2],
  // },
  // {
  //   id: 16,
  //   title: "SPecialty Cleaning",
  //   image: [dest6],
  //   desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   sidepara: "Lorem ipsum dolor sit amet consectetur adipiscing elit amet consectetur cipsum dolor sit amet consectetur adipiscing elit amet consect piscing elit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit",
  //   paraImage_one: [Para1],
  //   paraImage_two: [Para2],
  // },
];

export default Sdata;
