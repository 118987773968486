import React from "react";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import w1 from "../../../Assets/Images/works/work-1.png";
import w2 from "../../../Assets/Images/works/work-2.png";
import w3 from "../../../Assets/Images/works/work-3.png";

const Wdata = [
  {
    id: 1,
    cover: [w3],
    title: "Analysis",
    desc:
      [
        <li>Understanding Client's needs</li>,
      <li>Explore the situation.</li>,
      <li>Gather more Intel.</li>]
  },
  {
    id: 1,
    cover: [w1],
    title: "Execution",
    desc:
      [  <li>Generate a list of actions required.</li>,
      <li>Draft a problem statement (Quotation).</li>, 
      <li>Timeline & Implement action plan.</li>, 
  ],
  },
  {
    id: 1,
    cover: [w2],
    title: "Evaluation",
    desc:
      [<li>Customer's Review</li>,
      <Stack spacing={1} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Rating name="size-large" readOnly defaultValue={5} size="large" />
    </Stack> ],
  },
];
export default Wdata;
