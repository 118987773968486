import React from "react";
import "../../Components/HomeSection/popular/MostPopular.css";
//import Dcards from "../../Destinations/Dcards"
import Services2 from "./Service2";

const Service = () => {
  return (
    <>
      <section className="popular top">
        <div className="full_container">
          <div className="heading">
            <h1 style={{color:'#fff'}}>Who We Serve</h1>
            <h4 style={{color:'#fff'}}>Not limited</h4>
            <div className="line"></div>
          </div>
          <div className="content">
            <Services2 />
          </div>
        </div>
      </section>
    </>
  )
}

export default Service