import React from "react";
import "./houseCleaning.css";
import about2 from "../../Assets/Service Images/houseCleaning/period.jpg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";

const AboutCard2 = () => {
  return (
    <section >
      <div className="aboutCard mtop flex_space">
        <div className="row row1">
          <h1>
             <span>Periodically</span>
          </h1>
          <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "none" }}
              aria-label="contacts"
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Once-off" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Occasional" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Recurring" />
                </ListItemButton>
              </ListItem>
            </List>
        </div>
        <div className="row image">
          <img src={about2} alt="" />
          {/* <div className="control-btn">
            <button className="prev">
              <i className="fas fa-play"></i>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AboutCard2;
