import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sdata from "../Components/Destinations2/Sdata";
import EmptyFile from "../Common/Empty/EmptyFile";
import { useParams } from "react-router-dom";
import HeadTitle from "../Common/HeadTitle/HeadTitle";
import "./singlepage.css";
import { Button, Box, Modal, Typography } from "@mui/material";
import Team from "../Components/Destinations2/Packages/Team";

const SinglePage = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    let item = Sdata.find((item) => item.id === parseInt(id));
    if (item) {
      setItem(item);
    }
  }, [id]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "30%",
    left: "30%",
    // transform: 'translate(-50%, -50%)',
    width: "35%",
    height: "65vh",
    // bgcolor: 'background.paper',
    background: "linear-gradient(150deg, #c99fff -20%, #4a4eff)",
    border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <HeadTitle />

      {item ? (
        <section className="single-page top">
          <div className="container">
            <Link to="/residential" className="primary-btn back">
              <i className="fas fa-long-arrow-alt-left"></i> Go Back
            </Link>

            {/* --------- main-content--------- */}

            <article className="content flex">
              <div className="main-content">
                {/* <img src={item.image} alt='' /> */}
                <h1>{item.heading}</h1>
                <h3>{item.title2}</h3>
                <p>{item.desc2}</p>
                <p>{item.desc}</p>

                <div>{item.package}</div>

                {/* <h3>{item.heading2}</h3>
                <div className='para flex_space'>
                  <p>{item.sidepara}</p>
                  <p>{item.sidepara}</p>
                </div> */}

                <h3>{item.heading2}</h3>
                <div className="para flex_space">
                  <p>{item.sidepara}</p>
                  {/* <p>{item.sidepara}</p> */}
                </div>

                <div className="image grid1 ">
                  <img src={item.paraImage_one} alt="" />
                  <img src={item.paraImage_two} alt="" />
                </div>
                <h3>{item.heading3}</h3>
                <p>{item.desc3}</p>

                <div className="image grid1 "></div>
                <h3>{item.heading4}</h3>
                <p>{item.desc4}</p>

                <div className="image grid1 "></div>
                <h3>{item.heading5}</h3>
                <p>{item.desc5}</p>

                <div className="image grid1 "></div>
                <h3>{item.heading6}</h3>
                <p>{item.desc6}</p>

                {/* Modal start */}
                <Link to="/contact">
                  <Button
                    style={{ color: "#fff", fontWeight: 700, left: 300 }}
                    // onClick={handleOpen}
                  >
                    Place order
                  </Button>
                </Link>
                {/* <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <h2>Place your Order</h2>
                    <span> Search and Book Hotel</span>

                    <form>
                      <input type="text" placeholder="Seacrh City" />
                      <div className="flex_space">
                        <input type="date" placeholder="Check In" />
                        <input type="date" placeholder="Check Out" />
                      </div>
                      <div className="flex_space">
                        <input type="number" placeholder="Adult(s)(18+)" />
                        <input type="number" placeholder="Children(0- 17)" />
                      </div>
                      <input type="number" placeholder="Rooms" />
                      <input type="Submit" value="Search" className="submit" />
                    </form>
                  </Box>
                </Modal> */}

                {/* Modal end */}
              </div>

              {/* --------- main-content--------- */}

              {/* --------- side-content--------- */}
              <div className="side-content">
                <div className="">
                  <h2></h2>
                  {/* <p>{item.sidepara}</p> */}
                  <br />
                </div>
              </div>
              {/* --------- side-content--------- */}
            </article>
          </div>
        </section>
      ) : (
        <EmptyFile />
      )}
    </>
  );
};

export default SinglePage;
