import React from "react";
import dest1 from "../../Assets/Img/House Cleaning.jpg";
import dest2 from "../../Assets/Img/Occasion.jpg";
import dest3 from "../../Assets/Img/Janitorial.jpg";
import dest4 from "../../Assets/Img/Apartment.jpg";
import dest5 from "../../Assets/Img/Condo.jpg";
import dest6 from "../../Assets/Img/Day-Porter.jpg";
import dest7 from "../../Assets/Img/moving1.jpg";
import dest9 from "../../Assets/Img/Disinfecting.jpg";
import dest8 from "../../Assets/Img/Events.jpg";
import Para1 from "../../Assets/Images/singlepage/single-1.jpg";
import Para2 from "../../Assets/Images/singlepage/single-2.jpg";
import Team from "./Packages/Team";

const Sdata = [
  {
    id: 1,
    title: "One-Time Cleaning",
    title2: "",
    image: [dest1],
    heading: "One-Time Cleaning",
    heading2: [
      "Types of One-Time Cleaning Services",
      <p>
        Let us provide you with top reliable, high quality and professional
        cleaning service, wherever you call home. We are here for you! The
        cleaning Intelligent Agents cleaning service provides a variety of
        One-time professional house cleaning services which includes:
      </p>,
    ],
    // heading3: "Benefits of a One-Time Cleaning Visit from Molly Maid",
    // desc3: [
    //   <p>
    //     There are several occasions for one-time cleaning services and several
    //     benefits, too!
    //   </p>,
    //   <p>
    //     Get to know us. While most of our customers prefer cleaning services on
    //     a regular basis, we recognize that many homeowners choose to try out a
    //     cleaning company once before determining if they’re the best, most
    //     trustworthy choice for their cleaning needs. You can rest assured that
    //     our residential housekeepers go through a detailed vetting process and a
    //     thorough background check before training to join our team.
    //   </p>,
    //   <p>
    //     Catch up on cleaning. Call on us if you’re normally on top of your
    //     cleaning but have fallen behind. Our professional cleaning services can
    //     help you get your home back on track and let you pick up the mop again
    //     when you’re ready. This is especially handy for a one-time spring
    //     cleaning service or getting your home ready for the winter months.
    //   </p>,
    //   <p>
    //     {" "}
    //     Special (cleaning) operations. Call Molly Maid for those special
    //     cleaning missions. If you take care of most cleaning tasks, let our
    //     professional cleaners spend a little extra time on a specific
    //     high-traffic room or well-used appliance. Customize your one-time
    //     cleaning plan to focus on home cleaning tasks that need the most work,
    //     like mopping the kitchen and giving your bathroom a top-to-bottom shine
    //     Let’s do it again soon.
    //   </p>,
    //   <p>
    //     We’re confident that you’ll be so impressed with Molly Maid’s one-time
    //     cleaning that you will want us to come back. Many of our one-time
    //     cleaning customers turn into occasional cleaning customers that fit
    //     their needs or schedule. We also can make it a regular thing; trust
    //     Molly Maid for weekly, biweekly or monthly cleaning!
    //   </p>,
    // ],
    desc2:
      "After hosting a big event, you might need a helping hand to clean up the entire mess or maybe the house needs some spring cleaning or could simply be looking for a much-needed and thorough cleaning, you’re definitely going to need a helping hand. The experienced cleaning agents at The Cleaning Intelligent Agents (CIA) provide one-time cleaning services for any occasion, at any time of the year.",
    desc: "You can count on your local CIA for a one-time professional house cleaning that refreshes your home and makes it easier to maintain. That way, you can spend less time cleaning and more time enjoying the things you love.",
    sidepara: [
      <li>• One-time apartment cleaning</li>,
      <li>• One-time condo cleaning</li>,
      <li>• One-time house cleaning</li>,
      <li>• Move-in cleaning</li>,
      <li>• Move-out cleaning</li>,
      <li>• Special event cleaning</li>,
      <li>• Seasonal cleaning (spring cleaning)</li>,
      <li>• House cleaning gift certificates</li>,
      <li>• Yard cleaning and maintenance</li>,
    ],
  },

  {
    id: 2,
    title: "Occasional Cleaning",
    image: [dest2],
    heading: "Occasional Cleaning",
    // heading4: "A Professional Clean Designed Around Your Home",
    // heading3: "Detailed One-Time Cleaning Services, Every Time",
    heading2:
      "Get your One-Time Cleaning Service for Any Occasion or Any Reason",
    desc2: [
      <p>
        Is your home overdue for a spring cleaning? Have you been especially
        busy with work, kids, parties or any events? No matter the reason, we
        got you! You can trust the professional house cleaners at the{" "}
        <strong>CIA</strong> to provide the One-time cleaning service you need
        to get caught up.
      </p>,
    ],
    sidepara: [
      "Dust, dirt, clutter, grime and more can become overwhelming. Worry not, you can trust the CIA to help get your home back in shape with a good clean up and scrubbed down. Our occasional house cleaning services are perfect for situations like:",
      <li>• Spring cleaning</li>,
      <li>• Fall cleaning</li>,
      <li>• Special events and occasions</li>,
      <li>• Move-in cleaning</li>,
      <li>• Move-out cleaning</li>,
      <li>• Any time you need a hand!</li>,
      <li>• Yard cleaning and maintenance</li>,
    ],
    // desc3:
    //   "Molly Maid offers the same high-quality house cleaning services every time our customers welcome us into their homes. That means our one-time cleaning appointment is just as detail-oriented and thorough as any of our regular cleaning services.",
    // desc4:
    //   "With Molly Maid, there is no contract and no hassle. We work with every customer to design a personalized cleaning plan. Let our team clean every room in your home, or we can skip the rooms that don’t require our attention. Our licensed and trained house cleaners will invest their time and energy where it is needed most, using eco-friendly cleaning products and delivering the clean, comfortable home your family deserves. If our occasional cleaning services meet and exceed your expectations, our friendly staff would be happy to coordinate regular cleaning services to fit your preferences and budget.",
  },

  {
    id: 3,
    title: "Recurring Cleaning",
    title2: "Your Home, Your Ways",
    heading: "Recurring House Cleaning",
    image: [dest3],
    desc2:
      "We do understand things do come up, and often during inconvenient times. Sometimes schedules change, or your family gets busy. Worry not, for us, the CIA, got you covered! With flexible options to choose from, no contract, and no hustle.",
    package: <Team />,
  },

  {
    id: 4,
    title: "Apartment Cleaning",
    image: [dest4],
    heading: "Apartment Cleaning",
    // heading2: "Molly Maid - The Name Homeowners Trust",
    heading3: "What's Included in Apartment Cleaning Services?",
    heading4: "What Sets Our Apartment Cleaning Services Apart",
    desc: [
      <p>
        Make apartment living even simpler with cleaning agents from The
        Cleaning Intelligent Agents. Whether you need our services on a daily,
        weekly or monthly basis, you can look forward to our industry-leading
        services and flexible scheduling without the pressure of contracts.
      </p>,
      <p style={{ fontWeight: "bolder", fontSize: "15px", color: "black" }}>
        <strong>What’s our secret? Well, it’s really no secret:</strong>
      </p>,
      <li>• We're flexible.</li>,
      <li>• We're insured.</li>,
      <li>• We work with our customers' preferences.</li>,
      <li>• We offer a satisfaction warranty.</li>,
    ],
    desc3: [
      <p>
        People want the most value from their cleaning service provider.
        Fortunately, exceptional service is exactly what you’ll find when you
        choose the <strong>CIA</strong> for your apartment cleaning. You get
        much more than dusting, sweeping, and vacuuming. We clean your home from
        top to bottom, including:
      </p>,
      <li>
        • Kitchen counters, small appliances, stove, cabinets, and floors.
      </li>,
      <li>• Toilets, tubs, faucets, countertops, and floors.</li>,
      <li>
        • Living room and dining rooms, including furniture, floors, and
        cushions.
      </li>,
      <li>• Bedrooms, including mirrors, hanging frames, and more.</li>,
      <p style={{ fontWeight: "bolder", fontSize: "15px", color: "black" }}>
        <strong>
          We can also mix and match our cleaning services to meet your unique
          needs. Our apartment and condo cleaning services can include:
        </strong>
      </p>,
      <li>• Recurring apartment or condo cleaning services</li>,
      <li>• Apartment and condo move-in cleaning</li>,
      <li>• Apartment and condo move-out cleaning</li>,
    ],
    desc4: [
      <p>
        When you request condo, rental, or apartment cleaning from <b>Cleaning Intelligent Agents</b>,
        you’ll receive a higher standard of cleaning for guaranteed results. Our
        team arrives on time and follows a customized strategy to get your
        apartment or condo spotless with every visit. You also get a service
        with decades of experience cleaning apartments, flexibility, and
        attention to detail built into every service. Here’s a snapshot of
        reasons to choose The<b> CIA</b> to clean your home:
      </p>,
      <li>• On time every time!</li>,
      <li>
        • We’re a growing company with 100% customer satisfaction and
        trustworthy.
      </li>,
      <li>
        • Health is our number one priority! We get your apartment cleaner and
        healthier by removing germs, allergens, and other contaminants that can
        cause illness.
      </li>,
      <li>
        • Our professional house cleaning agents undergo extensive training to
        ensure we meet Cleaning Intelligent Agents high standards (and your
        own).
      </li>,
      <li>
        • We offer service to meet your cleaning needs, including weekly,
        bi-weekly, and monthly cleaning.
      </li>,
      <li>
        • We provide a unique plan designed to best clean your home at a small
        budget, we offer a free estimate of your home for those desiring a
        recurring cleaning schedule.
      </li>,
      <li>
        • No long-term obligation or contracts for recurring residential
        cleaning services.
      </li>,
      // <p>
      //   Choose the apartment cleaner more homeowners trust and see what a
      //   difference Molly Maid services can make in your home. Whether you're
      //   moving out and dead set on getting your security deposit back, moving in
      //   and want a fresh start, or simply want a clean apartment more often,
      //   we're here to help you get it done.
      // </p>,
    ],
    // sidepara: [
    //   <p>
    //     Our customers love the fact that we incorporate their wishes into our
    //     time-tested cleaning methods. By working with you to create your very
    //     own cleaning plan and following it every time we perform a cleaning
    //     service, we’re able to ensure consistent results that meet your needs.
    //     Each of our professional house cleaners undergoes extensive training to
    //     ensure that the services you receive are not only the Molly Maid way,
    //     but are your way as well. It’s our honor to wear a nationally recognized
    //     uniform and provide your most important space with the quality cleaning
    //     it deserves.
    //   </p>,
    //   <p>
    //     When you request condo, rental, or apartment cleaning services, you’ll
    //     receive guaranteed results without having to worry about cancellations
    //     or liability. Our team of trusted residential housekeepers will always
    //     arrive at your home when scheduled, with your cleaning plan in hand.
    //     It’s our goal to give you the free time you need and a clean home you
    //     can be proud of.
    //   </p>,
    //   <p>
    //     Molly Maid housekeeper services are also available for renters in need
    //     of move-in or move-out services. When your security deposit is on the
    //     line, trust our professionals to leave your rental spick and span.
    //   </p>,
    // ],
  },

  {
    id: 5,
    title: "Condo Cleaning",
    image: [dest5],
    heading: "Condo Cleaning",
    heading2: "High Standard Cleaning",
    heading3: "Your Trusted Apartment and Condo Cleaning Services",
    desc: "Make Condo living even simpler with cleaning agents from The Cleaning Intelligent Agents. Whether you need our services on a daily, weekly or monthly basis, you can look forward to our industry-leading services and flexible scheduling without the pressure of contracts.",
    sidepara: [
      "What makes us the most trusted apartment and condo cleaning company?",
      <li>• We're flexible.</li>,
      <li>• We're insured.</li>,
      <li>• We work with our customers' preferences.</li>,
      <li>• We offer a satisfaction warranty.</li>,
      <p>
        Whether you need our cleaning services weekly or just once in a while,
        you can look forward to our industry-leading services and flexible
        scheduling without the pressure of contracts.
      </p>,
    ],
    desc3: [
      "Mix and match our cleaning services to meet your unique needs. Our apartment and condo cleaning services can include:",
      <li>• Recurring apartment or condo cleaning services</li>,
      <li>• Apartment and condo move-in cleaning</li>,
      <li>• Apartment and condo move-out cleaning.</li>,
      <p>
        Our customers love that we incorporate their wishes into our time-tested
        cleaning methods. We work with you to create your very own cleaning plan
        and follow it every time we visit to ensure consistent results that meet
        your needs.
      </p>,
      <p>
        When you request our apartment or condo cleaning services, you’ll
        receive guaranteed results without worrying about cancellations or
        liability. Our team of trusted professional cleaners will always arrive
        at your home on the scheduled day with your cleaning plan in hand. It’s
        our goal to give you the free time you need and a clean apartment you
        can be proud of.
      </p>,
    ],
  },

  {
    id: 6,
    title: "House Cleaning",
    image: [dest6],
    heading: "House Cleaning",
    heading2: "What’s Included in Residential Cleaning Services",
    // heading3: "Why Molly Maid for Residential Cleaning Service?",
    desc: "Imagine having a clean home as often as you like without doing all the work. What would you do with the extra time if you didn't need to clean your home each week? When you partner with the CIA, we can help you enjoy more free time and a cleaner home with a residential cleaning plan that fits your schedule and cleaning needs.",
    sidepara: [
      "All of our residential cleaning plans are customized for you with no contracts, you can get the services you need when you need them, no strings attached. We got home cleaning services that fits your needs:",
      <li>• Bathroom cleaning</li>,
      <li>• Bedroom cleaning</li>,
      <li>• Kitchen cleaning</li>,
      <li>• Laundry room cleaning</li>,
      <li>• Dining room cleaning</li>,
      <li>• Organizing</li>,
      <li>• Yard cleaning and maintenance</li>,
      <p>
        Our cleaning plans are customized to meet your needs, therefore, you can
        add or omit specific rooms and areas in your home as needed.
      </p>,
    ],
    // desc3: [
    //   "Since 1984, Molly Maid has used proven cleaning methods to clean thousands of homes across North America. You can count on us to deliver customized residential cleaning as often as you like, and backed by the Neighborly Done Right Promise™. In addition, we offer:",
    //   <li>
    //     • Over 500 locations, so finding the services you need near you is easy.
    //   </li>,
    //   <li>
    //     • Cleaning teams that deliver a cleaner home while minimizing the
    //     presence of bacteria, viruses, and other pollutants.{" "}
    //   </li>,
    //   <li>
    //     • Team members that are vetted, insured, and professionally trained to
    //     deliver superior and consistent results with every visit.
    //   </li>,
    //   <li>
    //     • Housekeeping services customers prefer, including one-time cleaning,
    //     weekly, bi-weekly, and monthly cleaning service, and other options.
    //   </li>,
    //   <p>
    //     Your local Molly Maid requires no contracts, and we are happy to adjust
    //     your residential cleaning plan or the frequency of cleaning to meet your
    //     specific needs.
    //   </p>,
    // ],
  },

  {
    id: 7,
    title: "Move-In/Out cleaning",
    image: [dest7],
    heading: "Moving In cleaning",
    heading2: "What's Included in Move-In Cleaning Services?",
    // heading3: "Why Choose Molly Maid for Move-In Cleaning?",
    heading4: "Moving Out cleaning",
    heading5: "What's Included in Moving-out Cleaning Service",
    // heading6: "Why Use Molly Maid for Move-Out Cleaning Services?",
    desc: "You deserve a fresh, clean start in your new home. But after tackling the process of finding a new home, making sense of all your belongings, packing and cleaning your old home, and hiring a moving company, who has the energy or mind to think about tidying up? Instead, let the cleaning professionals at CIA take care of your move-in cleaning so you can get settled in your new home faster.",
    sidepara: [
      <p>
        While you’re busy wrapping up the packing at your old home, the Cleaning
        Intelligent Agents can tackle your move-in cleaning. The best time to
        clean your new home is while it's empty, so be sure to add the “CIA” to
        your move-in checklist. Our move-in cleaning service is customized for
        your needs and budget, so you pick what you need, including:
      </p>,
      <li>• Vacuum and sweep all floors</li>,
      <li>• Wipe down baseboards</li>,
      <li>• Scrub shower and bathtub</li>,
      <li>• Scrub toilets</li>,
      <li>• Clean sinks and countertops</li>,
      <li>• Clean kitchen appliances</li>,
      <li>• Dust everything</li>,
      <li>• Yard cleaning and maintenance</li>,
    ],
    // desc3: [
    //   <p>
    //     If a home looks and smells clean, it is, right? Unfortunately, that’s
    //     not always true! Removing surface dirt and spraying air fresheners can
    //     make a home look and smell fresh, but there's no substitute for
    //     professional cleaning. Thankfully, when our Molly Maid team leaves your
    //     new home, it will look and feel clean because it truly is. Our move-in
    //     and move-out cleaning services eliminate dirt, grime, and odors with
    //     top-to-bottom cleaning.
    //   </p>,
    //   <p>
    //     Molly Maid move-in cleaning services deliver guaranteed results that
    //     will put a sparkling shine on your new place and a smile on your face!
    //     Your move-in cleaning team cleans room by room and tracks down dirt and
    //     buildup no matter where it tries to hide. Whether you're moving out,
    //     moving in, or looking for a way to keep your new home clean week after
    //     week, Molly Maid is the answer.
    //   </p>,
    // ],
    desc4: [
      <p>
        You packed the dishes, de-cluttered the garage, hired the movers, and
        signed the papers. Now what? Other than loading up and getting a move
        on, the only thing left will be your final cleanup at your old place.
        The <strong>CIA</strong> keeps things moving by tackling all your
        move-out cleaning tasks for a smoother, less stressful moving
        experience.
      </p>,
      <p>
        Now that the rooms are cleared out, it’s time to make your home look
        move-in ready for the next occupants. Many residential rental contracts
        require a “broom-clean,” while others call for detailed cleaning that
        can include everything from the stove to the baseboards.
      </p>,
      <p>
        Even if it’s not required, performing a thorough cleaning before moving
        out is beneficial to both parties, especially if you are awaiting the
        return of your security deposit. Wrap things up the right way and leave
        your old space better than you found it with a professional move-out
        cleaning service from the CIA.
      </p>,
    ],
    desc5: [
      <p>
        We have customized your move-out cleaning service to meet your cleaning
        needs and moving schedule. If there is a room you would rather we skip
        or a service that's not needed, simply let us know. We’ll clean the rest
        of your space with a keen eye for detail. We can also give you a clean,
        fresh start in your new home with a move-in cleaning that covers all the
        bases.
      </p>,
      <p>Our standard move-out cleaning services include:</p>,
      <li>• Vacuuming and sweeping all floors</li>,
      <li>• Wiping down baseboards</li>,
      <li>• Wiping cabinets and drawers inside and out</li>,
      <li>• Scrubbing the shower and bathtub</li>,
      <li>• Scrubbing toilets</li>,
      <li>• Cleaning sinks and countertops</li>,
      <li>• Cleaning kitchen appliances</li>,
    ],
    // desc6: [
    //   <p>
    //     When you choose Molly Maid to handle the final cleanup at your old home,
    //     you’ll get guaranteed results and peace of mind knowing there are no
    //     loose ends. Your professional cleaning team will scrub, mop, and dust
    //     while you take care of the final details and hit the road. Whether
    //     you're moving out, moving in, or looking for services to keep your new
    //     home clean week after week, your local Molly Maid is ready to help.{" "}
    //   </p>,
    // ],
  },

  {
    id: 8,
    title: "Event Cleaning",
    image: [dest8],
    heading: "EVENT CLEANING",
    heading4: "What’s Included in Special Event Cleaning Services?",
    heading2: "Why Should I Hire an Event Cleaning Service?",
    heading3: "How it works",
    desc: [
      <p>
        Planning a special event is like trying to keep a lot of plates spinning
        simultaneously. If you’re not organized, everything can come crashing
        down. The Cleaning Intelligent Agents can help you scratch one thing off
        your special event planning to-do list with our event cleaning services
        designed to suit your needs. Whether you’re hosting a party, preparing
        to welcome a new bundle of joy, or planning an event, your local CIA can
        help you get it all done.
      </p>,
    ],
    sidepara:
      "There’s nothing like having a team of event cleaning service professionals to help your special day go as smoothly as possible. Don’t stress about getting everything clean—our experienced team can ensure your guests are wowed the moment they walk into your clean, refreshed space. We can also help out after your event and return your space to how it looked before.",
    desc4: [
      <p>
        Our special event cleaning team can assist with preparation and/or
        post-event cleanup. Tell us what you need, and we’ll develop an action
        plan to help you get it all done. Before your event starts, we can
        vacuum rugs, mop down surfaces, and tidy up the space so it looks
        perfect for your special occasion. Each cleaning task helps ensure your
        location will shine and is ready to receive guests.
      </p>,
      <p>
        We can help make your life easier after the event too! Need garbage cans
        emptied and liners replaced, debris collected, and the space back
        together again? How about removing decorations to ensure that the site
        looks just like it did before the event? After everything is removed,
        our event professional cleaning service team can do a thorough cleaning
        to ensure everything looks just right.
      </p>,
    ],
    desc3:
      "We understand that everyone wants to feel comfortable with the people they let into their home. This is why we can meet with you first, to understand your cleaning needs and preferences. Then we can develop a customized cleaning plan that works for you and your schedule. We’ll also note what you do and don’t want to have cleaned and update any changes you make to your cleaning plan along the way.",
  },

  {
    id: 9,
    title: "Eco-Friendly Cleaning",
    image: [dest9],
    heading: "ECO-FRIENDLY (DIINFECTING) CLEANING",
    heading2: "Superior Quality Eco-Friendly Cleaning Company",
    heading3: "Our Commitment to Safe and Sustainable Cleaning Practices",
    desc4:
      "We believe our products are the safest way to disinfect your home, but we will always strive to meet your needs. For some clients, that means restricting the use of certain cleaning solutions or disinfectants to specific rooms or areas of your home. Nontoxic cleaning practices are a part of the personalized cleaning plan we create with every client so that our team focuses on the rooms of your home that need professional attention.",
    heading4: "We Clean with Safe and Responsible Products",
    desc: "We value the trust required to welcome the CIA into your home. That's why we use safe and sustainable cleaning products that protect you and your family from germs and harsh chemicals. Our environmentally friendly cleaning services help us earn your trust while putting you and the environment first.",
    sidepara: [
      <p>
        We offer cleaning solutions tailored to each customer’s unique needs. If
        you are curious about the cleaning products we use, we will be happy to
        inform you and make adjustments to ensure you're comfortable with every
        aspect of our visit.
      </p>,
    ],
    desc3: [
      <p>
        We're dedicated to helping you find the right balance between protecting
        the environment and maintaining a clean, healthy home. That's why our
        house cleaners use eco-friendly cleaning products and follow other
        company standards like:
      </p>,
      <li>• Using non-toxic cleaning supplies whenever possible</li>,
      <li>• Avoiding products with petroleum, ammonia, and alcohol</li>,
      <li>• Diluting bleach to safe levels</li>,
      <p>
        We also carry out a variety of environmentally friendly practices, such
        as:
      </p>,
      <li>• Optimizing travel routes to maximize efficiency</li>,
      <li>• Reducing product waste</li>,
      <li>• • Reusing bottles</li>,
    ],
  },
];

export default Sdata;
