import React, { useState } from "react"
import Dcards from "./Dcards"
import "../gallery/Gallery.css"
import "./Destinations.css"
import Sdata from "./Sdata"
import { Link } from "react-router-dom";
import img1 from "../../Assets/Img/Commercial.jpg";
import img2 from "../../Assets/Img/Residential/Clean Home/5.jpg";
import img3 from "../../Assets/Img/handyman.jpg";

const AllItem = () => {
  const [items, setIems] = useState(Sdata)
  return (
    <>
      <section className='gallery desi mtop'>
        <div className='container'>
          <div className='content grid'>
          
      <div className="items">
        <div className="img">
          <img src={img2} alt="Gallery Image" />
          <Link to="/houseCleaning" className="blogItem-link">
            <i className="fas fa-external-link-alt"></i>
          </Link>
        </div>
        <div className="title">
          <Link to="/houseCleaning">
            <h3>House Cleaning</h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img1} alt="Gallery Image" />
          <Link to="/officeCleaning" className="blogItem-link">
            <i className="fas fa-external-link-alt"></i>
          </Link>
        </div>
        <div className="title">
          <Link to="/officeCleaning">
            <h3>Office Cleaning</h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img3} alt="Gallery Image" />
          <a
            href="https://www.starwoodandironcrafts.co.za"
            target="_blank"
            className="blogItem-link"
          >
            <i className="fas fa-external-link-alt"></i>
          </a>
        </div>
        <div className="title">
          <a href="https://www.starwoodandironcrafts.co.za" target="_blank">
            <h3>Handyman Service </h3>
          </a>
        </div>
      </div>
    
          </div>
        </div>
      </section>
    </>
  )
}

export default AllItem

/*
import React, { useState } from "react"
import Dcards from "./Dcards"
import "../gallery/Gallery.css"
import "./Destinations.css"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import Sdata from "../Destinations/Sdata"

const Destinations = () => {
  const [items, setIems] = useState(Sdata)
  return (
    <>
      <HeadTitle />
      <section className='gallery desi top'>
        <div className='container'>
          <div className='content grid'>
            {items.map((item) => {
              return <Dcards key={item.id} item={item} />
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default Destinations*/
