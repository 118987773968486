export const fastFoodProducts = [
  {
    id: "01",
    title: "Background",
    details: 'We can all agree that in seven days, a lot can happen. Either busy homes or large families, a visit from an experienced professional house cleaner is a must. Save yourself valuable time and avoid the stress and hustle by finding a day of the week that’s most convenient for us to visit. If you feel like the need to adjust the appointment by a day or two or even by a few hours, just let us know. We’re flexible and quick to adapt to any changes. We conduct Deep Cleaning service followed by three Basic Cleans in a four-week rotation. Whatever the cleaning job, our professionally-trained Agents will get it done. ',
    // imgUrl: fastFood04,
  },
];

export const riceMenuProducts = [
  {
    id: "02",
    title: "Background",
    details: 'Every other week is a terrific way to get some extra hands to help with upkeep. Feeling like doing a little work around the house, our bi-weekly house cleaning service may be the best option. We conduct a Deep Clean, skip a week, Basic Clean, skip a week and then repeat. This four-week rotation helps us not wreck your schedule.',
    // imgUrl: salad,
  },
];


export const pizzaProducts = [
  {
    id: "03",
    title: "Background",
    details: 'Give your home a thorough cleaning once a month. Monthly cleaning is a great option for families that can handle the little things from week to week but need more help to tackle bigger cleaning tasks. It’s also a great way to address issues like allergens, bacteria, pests, dust buildup and dust mites. Count on the Cleaning Intelligent Agents to vacuum, dust, mop, and sanitize dirtiest, cluttered and affected areas of your home. We work with you to design a personalized cleaning plan to focus on the spaces that need the most attention, like cleaning the kitchen or scrubbing the tub. If you just need things spruced up monthly The CIA is your solution.',
    // imgUrl: pizzaImg01,
  },
];
