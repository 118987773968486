import React from "react";
import "../../App.css";
import Hero from "../HomeSection/Hero";
import HomeAbout from "../HomeSection/HomeAbout";
import MostPopular from "../HomeSection/popular/MostPopular";
import DestinationHome from "../HomeSection/Destina/DestinationHome";
import Download from "../HomeSection/Download/Download";
import Works from "../HomeSection/Works/Works";
import Gallery from "../HomeSection/gallery/Gallery";
import Video from "../../Common/Background/Video";
import Service from "../Serve/Service";
import Team from "../Team/Team";

const Home = () => {
  return (
    <>
      {/* <Team/> */}
      {/* <Hero /> */}
      <Video />
      <HomeAbout />
      <DestinationHome />
      {/* <MostPopular /> */}
      <Service />
      {/* <Download /> */}
      <Works />
      {/* <Gallery /> */}
    </>
  );
};

export default Home;
