import React from "react";
import "./houseCleaning.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import about2 from "../../Assets/Service Images/houseCleaning/Day-Porter.jpg";

const AboutCard = () => {
  return (
    <>
        <section>
      <h2 style={{textAlign:'center'}}>House Cleaning & Maid Services</h2>
      <br/>
          <p>
            Imagine having a clean home as often as you like without doing all
            the work. What would you do with the extra time if you didn't need
            to clean your home each week? When you partner with the CIA, we can
            help you enjoy more free time and a cleaner home with a residential
            cleaning plan that fits your schedule and cleaning needs.
          </p>
          </section>
          <br/>
      <div className="aboutCard mtop flex_space">
    
        <div className="row row1">
         
          <h1>
            <span>What we Offer</span>
          </h1>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "none" }}
            aria-label="contacts"
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Basic & Deep Cleaning" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Preventative Disinfection Service" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Organizing" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Post-Construction" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Specialty Cleaning" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Handyman Services" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Personal Maid" />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <div className="row image">
          <img src={about2} alt="" />
          {/* <div className="control-btn">
            <button className="prev">
              <i className="fas fa-play"></i>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AboutCard;
