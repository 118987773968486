import React, { useState, useRef } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import {
  TbBrandTiktok,
  TbBrandFacebook,
  TbBrandInstagram,
  TbBrandTwitter,
  TbBrandLinkedin,
  TbBrandYoutube,
  TbBrandWhatsapp,
} from "react-icons/tb";
import emailjs from "@emailjs/browser";

const Result = () => {
  return (
    <>
      <p>Thank you for your message! We will get back to you shortly!</p>
    </>
  );
};
const Footer = () => {
  const form = useRef();
  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_nanq43n",
        "template_8vpmxpa",
        form.current,
        "UDqynssnARp4qIk4r"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 5000);

  const style = {
    color: "#ffffff",
    marginRight: "1rem",
    background: "linear-gradient(150deg, #c99fff -20%, #4a4eff)",
    width: "30px",
    height: "30px",
    lineHeight: "40px",
    textAlign: "center",
    border: "1px solid #e7e3e3",
    borderRadius: "5px",
  };
  return (
    <>
      <footer>
        <div className="container grid">
          <div className="box1">
            <h2>ABOUT US</h2>
            <p>
              We are a Cleaning company that consists of Highly skilled
              Professional cleaning agents whose purpose is to serve the Nation
              by keeping it Clean and Healthy for the benefit of every
              individual, community and entity for generations to come.
            </p>
            <div className="icon">
              <a
                href="https://www.facebook.com/people/Cleaning-Intelligent-Agents-Pty-Ltd/100090744556794/"
                target="_blank"
              >
                <TbBrandFacebook size={20} style={style} />
              </a>
              <a
                href="https://www.instagram.com/cleaningintelligentagents/"
                target="_blank"
              >
                <TbBrandInstagram size={20} style={style} />
              </a>
              <a
                href="https://www.linkedin.com/company/cleaning-intelligent-agents/"
                target="_blank"
              >
                <TbBrandLinkedin size={20} style={style} />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=%2B27686853498&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <TbBrandWhatsapp size={20} style={style} />
              </a>
              {/* <a href="https://www.google.com" target="_blank">
                <TbBrandTwitter size={20} style={style} />
              </a>
              <a href="https://www.google.com" target="_blank">
                <TbBrandYoutube size={20} style={style} />
              </a>
              <a href="https://www.google.com" target="_blank">
                <TbBrandTiktok size={20} style={style} />
              </a> */}
            </div>
          </div>

          <div className="box">
            <h2>NAVIGATION</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className="box post">
            <h2>RECENT POSTS</h2>
            <ul>
              <li>
                <p>Get 50% off your third cleaning session</p>
                <label className="fa fa-calendar-alt"></label>
                {/* <span>01 Oct 2020</span> */}
              </li>
              {/* <li>
                <p>Lorem ipsum dolor sit amet sectetur adipiscing elit amet</p>
                <label className="fa fa-calendar-alt"></label>
                <span>01 Oct 2020</span>
              </li>
              <li>
                <p>Lorem ipsum dolor sit amet sectetur adipiscing elit amet</p>
                <label className="fa fa-calendar-alt"></label>
                <span>01 Oct 2020</span>
              </li> */}
            </ul>
          </div>

          <form
            ref={form}
            sx={{ "& > :not(style)": { m: 1 } }}
            action=""
            onSubmit={sendEmail}
          >
            <div className="box">
              <h2>NEWSLETTER</h2>
              <p>Stay updated with all our latest offers</p>
              <input
                type="email"
                name="subscribe"
                id=""
                placeholder="Enter Your Email Here"
                required
              />
              <button className="secondary-btn" type="submit">
                <b>SUBSCRIBE</b>
              </button>
              <div className="row">{result ? <Result /> : null}</div>
            </div>
          </form>
        </div>
      </footer>
      <div className="legal">
        <p>Copyright © 2023 The CIA. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
