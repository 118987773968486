import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../Assets/Img/Serve/Corporate.jpg";
import img2 from "../../Assets/Img/Serve/Residential.jpg";
import img3 from "../../Assets/Img/Serve/Retail.jpg";
import img4 from "../../Assets/Img/Serve/Health.jpg";
import img5 from "../../Assets/Img/Serve/Fitness.jpg";
import img6 from "../../Assets/Img/Serve/School.jpg";
import img7 from "../../Assets/Img/Serve/Hotel.jpg";
import img8 from "../../Assets/Img/Serve/Bars.jpg";
import img9 from "../../Assets/Img/Serve/Construction.jpg";
import img10 from "../../Assets/Img/Serve/Sports.jpg";
import img11 from "../../Assets/Img/Serve/Events.jpg";
import img12 from "../../Assets/Img/Serve/Car.jpg";

const Dcards2 = () => {
  return (
    <>
      <div className="items">
        <div className="img">
          <img src={img1} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Corporate Buildings</h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img2} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Residential Buildings</h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img3} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Retail </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img4} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Healthcare Facilities </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img5} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Fitness Centers </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img6} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Schools</h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img7} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Hotels </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img8} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Restaurants & Bars </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img9} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Construction Sites </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img10} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Sports Fields/Arenas </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img11} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Event Venues </h3>
          </Link>
        </div>
      </div>
      <div className="items">
        <div className="img">
          <img src={img12} alt="Gallery Image" />
          <Link className="blogItem-link">
            {/* <i className="fas fa-external-link-alt"></i> */}
          </Link>
        </div>
        <div className="title">
          <Link>
            <h3>Car Owners </h3>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Dcards2;
