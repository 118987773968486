import React, { useState } from "react";
import Dcards2 from "./Dcards2";
import "../gallery/Gallery.css";
import "./Destinations.css";
import Sdata from "./Servicedata";

const Services2 = () => {
  const [items, setIems] = useState(Sdata);
  return (
    <>
      <section className="gallery desi mtop">
        <div className="container">
          <div className="content grid">
            <Dcards2 />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services2;
