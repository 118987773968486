import React from "react";
import "./Team.css";

const TeamCard = (props) => {
  const { title, imgUrl, details } = props.item;

  return (
    <div className="single_product_7">
      {/* <div className="product_img_7">
        <img src={imgUrl} alt="" className="w-100" />
      </div> */}

      <div className="product_content_7">
        <div className="d-flex align-items-center justify-content-between">
          <span className="price_7 d-flex align-items-center">
            <span>{details}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
