import React from "react";
import "./officeCleaning.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import Img1 from "../../Assets/Img/Janitorial.jpg";
import about2 from "../../Assets/Service Images/officeCleaning/Janitorial.jpg";

const AboutCard = () => {
  return (
    <>
      <section>
        <h2 style={{ textAlign: "center" }}>Commercial Cleaning Services</h2>
        <br/>
        <p>
          Whether you run a small business or corporate office, keeping it clean
          is important to maintain a healthy work environment, which benefits
          employees and can help your business attract new clients and
          customers. Your local CIA makes commercial cleaning convenient for
          business owners and facility managers who already have enough on their
          plates without worrying about keeping things clean. Cleaning
          Intelligence Agents' superior cleaning services help create a
          healthier indoor environment for employees and visitors.
        </p>
        <br/>
        <p>
          Our professional commercial cleaning services also frees up time for
          you to focus on other important things—like running a business or
          organization. When you need a clean office or building that impresses,
          you want small business cleaning services from the CIA with variety
          cleaning solutions that suits all businesses and facilities.
        </p>
      </section>
      <br/>
      <div className="aboutCard mtop flex_space">
        <div className="row row1">
          <h1>
            <span>What we Offer</span> 
          </h1>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "none" }}
            aria-label="contacts"
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Basic & Deep Cleaning" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Preventative Disinfection Service" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Day-Porter" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Post-Construction" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Janitor Services" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Handyman Services" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Pre-Event & Post-Event services" />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <div className="row image">
          <img src={about2} alt="" />
          {/* <div className="control-btn">
            <button className="prev">
              <i className="fas fa-play"></i>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AboutCard;
