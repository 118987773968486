import React, { useState, useRef } from "react";
import "./Contact.css";
import "../../Common/Navbar/Navbar.css";
import Map from "./Map";
import { MdOutlineSupportAgent, MdOutlineEmail } from "react-icons/md";
import { Button, Box, Modal } from "@mui/material";
import { AddressAutofill } from "@mapbox/search-js-react";
import { FormControl } from "react-bootstrap";
import emailjs from "@emailjs/browser";

const Result = () => {
  return (
    <>
      <p
        style={{
          boxSizing: "border-box",
          borderRadius: "5px",
          borderColor: "#ECECEC",
          background: "#685FD5",
          color: "black",
          margin: "10px",
          textAlign: "center",
        }}
      >
        Thank you for your message! We will get back to you shortly!
      </p>
    </>
  );
};

const ContactFrom = () => {
  const form = useRef();
  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_nanq43n",
        "template_8vpmxpa",
        form.current,
        "UDqynssnARp4qIk4r"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 5000);

  const style = {
    position: "absolute",
    top: "30%",
    left: "30%",
    // transform: 'translate(-50%, -50%)',
    width: "35%",
    height: "65vh",
    // bgcolor: 'background.paper',
    background: "linear-gradient(150deg, #c99fff -20%, #4a4eff)",
    border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    width: "100%",
    height: "7vh",
    border: "2px solid #ECECEC",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    paddingLeft: "20px",
  };

  return (
    <>
      <section className="contact mtop">
        <div className="container flex">
          <div className="main-content">
            <h2>Contact From</h2>
            <p>Fill out the form below, we will get back you soon.</p>

            <form
              ref={form}
              sx={{ "& > :not(style)": { m: 1 } }}
              onSubmit={sendEmail}
            >
              <div className="grid1">
                <div className="input">
                  <input
                    placeholder="Full Name"
                    style={{ height: "5px" }}
                    type="text"
                    name="fname"
                    required
                  />
                </div>
                {/* <div className="input">
                  <input
                    placeholder="Last Name"
                    style={{ height: "5px" }}
                    type="text"
                    name="lastname"
                    value={name}
                    onChange={(e) => setLname(e.target.value)}
                    required
                  />
                </div> */}
                <div className="input">
                  <input
                    placeholder="Phone Number"
                    style={{ height: "5px" }}
                    type="number"
                    name="phone"
                    required
                  />
                </div>
                <div className="input">
                  <input
                    style={{ height: "5px" }}
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>

                {/* Address line starts here */}
                <AddressAutofill accessToken="">
                  <input
                    style={{ height: "5px" }}
                    name="address"
                    placeholder="Address"
                    type="text"
                    autoComplete="address-line1"
                    required
                  />
                </AddressAutofill>
                <input
                  style={{ height: "5px" }}
                  name="apartment"
                  placeholder="Apartment number"
                  type="number"
                  autoComplete="address-line2"
                />
                <input
                  style={{ height: "5px" }}
                  name="city"
                  placeholder="City"
                  type="text"
                  autoComplete="address-level2"
                  required
                />
                <input
                  style={{ height: "5px" }}
                  name="province"
                  placeholder="Province"
                  type="text"
                  autoComplete="address-level1"
                  required
                />
                <input
                  style={{ height: "5px" }}
                  name="postcode"
                  placeholder="Postcode"
                  type="text"
                  autoComplete="postal-code"
                  required
                />

                {/* Address line ends here */}
                <div className="input">
                  <input
                    placeholder="Company Name"
                    style={{ height: "5px" }}
                    type="text"
                    name="company"
                  />
                </div>

                <div className="" style={style2}>
                  <span>
                    <small>Service Required</small>
                    <label required={true}>
                      {" "}
                      <select name="subject" required>
                        <option></option>
                        <option value="One-time cleaning">
                          One-time cleaning
                        </option>
                        <option value="occasional">occasional Cleaning</option>
                        <option value="Reccurring">Recurring Cleaning</option>
                        <option value="apartment">Apartment Cleaning</option>
                        <option value="condo">Condo Cleaning</option>
                        <option value="house">House Cleaning</option>
                        <option value="move-in">Move-In</option>
                        <option value="move-out">Move-Out</option>
                        <option value="event">Event Cleaning</option>
                        <option value="eco-friendly">
                          Eco-Friendly Cleaning
                        </option>
                        <option value="other">Other</option>
                      </select>
                    </label>
                  </span>
                </div>
              </div>
              {/* <input type="file" /> */}
              <div className="input inputlast">
                <span>
                  Write Your Message <label>*</label>
                </span>
                <textarea
                  cols="30"
                  rows="10"
                  name="message"
                  placeholder="How may we assist?"
                ></textarea>
              </div>
              <div>
                <button className="primary-btn">Send</button>
              </div>
              <div className="row">{result ? <Result /> : null}</div>
            </form>

            {/* <div>
              <button
                className="secondary-btn"
                style={{ color: "#fff", top: 30, marginBottom: "30px" }}
                onClick={handleOpen}
              >
                Request a Quote
              </button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <h2>Get an Instant Quote</h2>
                  <span> Search and Book Hotel</span>

                  <form>
                    <input type="text" placeholder="Seacrh City" />
                    <div className="flex_space">
                      <input type="date" placeholder="Check In" />
                      <input type="date" placeholder="Check Out" />
                    </div>
                    <div className="flex_space">
                      <input type="number" placeholder="Adult(s)(18+)" />
                      <input type="number" placeholder="Children(0- 17)" />
                    </div>
                    <input type="number" placeholder="Rooms" />
                    <input type="Submit" value="Search" className="submit" />
                  </form>
                </Box>
              </Modal>
            </div> */}
            <Map />
          </div>

          <div className="side-content">
            <div class="fusion-text fusion-text-1">
              <p>
                <i
                  class="fb-icon-element-1 fb-icon-element fontawesome-icon fa-phone-alt fas circle-no fusion-text-flow"
                  style={{
                    awbIconcolor: "#1b65ab",
                    awbIconcolorHhover: "#1b65ab",
                    awbFontSize: "16px",
                    awbMarginRight: "8px",
                    color: "#1b65ab",
                  }}
                ></i>{" "}
                <strong>Phone</strong>
              </p>
              <p style={{ paddingLeft: "40px" }}>
                <a href="tel:+27686853498">(+27)68-685-3498</a>
              </p>

              <p>
                <i
                  class="fb-icon-element-1 fb-icon-element fontawesome-icon fa-envelope fas circle-no fusion-text-flow"
                  style={{
                    awbIconcolor: "#1b65ab",
                    awbIconcolorHhover: "#1b65ab",
                    awbFontSize: "16px",
                    awbMarginRight: "8px",
                    color: "#1b65ab",
                  }}
                ></i>{" "}
                <strong>Email us</strong>
              </p>
              <p style={{ paddingLeft: "40px" }}>
                <a href="">info@cia.co.za</a>
              </p>

              <p>
                <i
                  class="fab fa-whatsapp"
                  aria-hidden="true "
                  style={{
                    awbIconcolor: "#1b65ab",
                    awbIconcolorHhover: "#1b65ab",
                    awbFontSize: "16px",
                    awbMarginRight: "8px",
                    fontWeight: "bold",
                    color: "#1b65ab",
                  }}
                ></i>{" "}
                <strong>WhatsApp</strong>
              </p>
              <p style={{ paddingLeft: "40px" }}>
                <a href="https://wa.me/+27686853498">(+27)68-685-3498</a>
              </p>

              {/* <p>
                <i
                  style={{
                    awbIconcolor: "#1b65ab",
                    awbIconcolorHhover: "#1b65ab",
                    awbFontSize: "16px",
                    awbMarginRight: "8px",
                    fontWeight: "bolder",
                    fontSize: "17px",
                    color: "#1b65ab",
                  }}
                >
                  <MdOutlineSupportAgent />
                </i>{" "}
                <strong>Live Chat</strong>
              </p>
              <p style={{ paddingLeft: "40px" }}>
                <a href="https://tawk.to/chat/6401473b31ebfa0fe7f05dff/1gqifsgl3">
                  Chat with an Agent
                </a>
              </p> */}

              <p>
                <i
                  class="fb-icon-element-2 fb-icon-element fontawesome-icon fa-clock fas circle-no fusion-text-flow"
                  style={{
                    awbIconcolor: "#1b65ab",
                    awbIconcolorHhover: "#1b65ab",
                    awbFontSize: "16px",
                    awbMarginRight: "8px",
                    color: "#1b65ab",
                  }}
                ></i>{" "}
                <strong>Office Hours</strong>
              </p>
              <p style={{ paddingLeft: "40px" }}>Sun-Fri: 8:00 – 17:30</p>
              <p style={{ paddingLeft: "40px" }}>Sat: closed</p>
              <p>
                <i
                  class="fb-icon-element-3 fb-icon-element fontawesome-icon fa-map-marker-alt fas circle-no fusion-text-flow"
                  style={{
                    awbIconcolor: "#1b65ab",
                    awbIconcolorHover: "#1b65ab",
                    awbFontSize: "18px",
                    awbMarginRight: "9px",
                    color: "#1b65ab",
                  }}
                ></i>{" "}
                <strong>Address</strong>
              </p>
              <p style={{ paddingLeft: "40px" }}>
                Claremont
                <br />
                Cape Town, 7708
              </p>
            </div>

            <div className="icon" style={{ gap: "10px" }}>
              <h3>Follow Us</h3>

              <div className="flex_space" style={{ gap: "10px" }}>
                <a
                  href="https://www.facebook.com/people/Cleaning-Intelligent-Agents-Pty-Ltd/100090744556794/"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/cleaningintelligentagents/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/cleaning-intelligent-agents/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Map style={{marginTop: '10px', marginBottom: '50px', }} /> */}
    </>
  );
};

export default ContactFrom;
