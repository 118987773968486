import React from "react";
import "./About.css";
import AboutCard from "./AboutCard";
import HeadTitle from "../../Common/HeadTitle/HeadTitle";
import about1 from "../../Assets/Img/AboutUs/6.jpg";
import about3 from "../../Assets/Img/AboutUs/4.jpg";
import Team from "../Team/Team";
import AboutCard2 from "./AboutCard2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";

const About = () => {
  return (
    <>
      <HeadTitle />

      <section className="about top">
        <div className="container">
          <AboutCard />
        </div>
      </section>

      <section className="features top">
        <div className="container aboutCard flex_space">
          <div className="row row1">
            <h1>
              What <span>we</span> Offer
            </h1>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "none" }}
              aria-label="contacts"
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Basic & Deep Cleaning" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Preventative Disinfection Service" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Organizing" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Post-Construction" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Specialty Cleaning" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Handyman Services" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
          <div className="row image">
            <img src={about3} alt="" />
            <div className="control-btn">
              <button className="prev">
                <i className="fas fa-play"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="about top">
        <div className="container">
          <AboutCard2 />
        </div>
      </section>

      <section className="features top">
        <div className="container aboutCard flex_space">
          <div className="row row1">
            <h1>
              Our <span>Vision</span>
            </h1>
            <p> 
              With our core values and principles, we hope to help build a better, productive and welcoming environment for each resident, community and organization.
            </p>
            <p> <h2>2030</h2><strong>
              We aim to create over 10</strong><small>thousand</small> <strong>job opportunities for our youth for a better tomorrow</strong>
            </p>
            {/* <button className='secondary-btn'>
              Explore More <i className='fas fa-long-arrow-alt-right'></i>
            </button> */}
          </div>
          <div className="row image">
            <img src={about1} alt="" />
            <div className="control-btn">
              <button className="prev">
                <i className="fas fa-play"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      {/* <Team /> */}
    </>
  );
};

export default About;
