import p1 from '../../Assets/Images/profile/profile1.jpg';
import p2 from '../../Assets/Images/profile/profile2.jpg';
import p3 from '../../Assets/Images/profile/profile3.jpg';
import p4 from '../../Assets/Images/profile/profile4.jpg';
import p5 from '../../Assets/Images/profile/profile5.jpg';
import p6 from '../../Assets/Images/profile/profile6.jpg';



const Tdata = [
  {
    id: 1,
    desc: "Drop us a review on Google reviews and on our Social Media platforms.",
    name: "KEVIN MARTHIN",
    profile: [p1],
    post: "Developer",
  },
  // {
  //   id: 2,
  //   desc: "Lorem ipsum dolor sit amet consectetur ipiscing elit amet consectetur piscing elit consectetur adipiscing elit sed et eletum. orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   name: "JOE GABRIEL",
  //   profile: [p2],
  //   post: "Designer",
  // },
  // {
  //   id: 3,
  //   desc: "Lorem ipsum dolor sit amet consectetur ipiscing elit amet consectetur piscing elit consectetur adipiscing elit sed et eletum. orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   name: "JOIN MANSOOR",
  //   profile: [p3],
  //   post: "Support-Marketing",
  // },
  // {
  //   id: 4,
  //   desc: "Lorem ipsum dolor sit amet consectetur ipiscing elit amet consectetur piscing elit consectetur adipiscing elit sed et eletum. orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   name: "SUSAN DAY",
  //   profile: [p4],
  //   post: "CEO",
  // },
  // {
  //   id: 5,
  //   desc: "Lorem ipsum dolor sit amet consectetur ipiscing elit amet consectetur piscing elit consectetur adipiscing elit sed et eletum. orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   name: "MICHAEL DAVID",
  //   profile: [p5],
  //   post: "Consultant",
  // },
  // {
  //   id: 6,
  //   desc: "Lorem ipsum dolor sit amet consectetur ipiscing elit amet consectetur piscing elit consectetur adipiscing elit sed et eletum. orem ipsum dolor sit amet consectetur adipiscing elit amet consectetur piscing elit amet consectetur adipiscing elit sed et eletum nulla eu placerat felis etiam tincidunt orci lacus id varius dolor fermum sit amet.",
  //   name: "MEGAN MILLER",
  //   profile: [p6],
  //   post: "Founder",
  // },
]

export default Tdata
