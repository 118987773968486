import React from "react"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import ContactForm2 from "./ContactForm2"
import ContactFrom from "./ContactFrom"



const Contact = () => {
  return (
    <>
      <HeadTitle />
      <ContactFrom />
      {/* <ContactForm2/> */}
   
    </>
  )
}

export default Contact
