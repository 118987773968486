import React from "react";
import "./About.css";
import about2 from "../../Assets/Img/AboutUs/7.jpg";

const AboutCard = () => {
  return (
    <>
      <div className="aboutCard mtop flex_space">
        <div className="row row1">
          <h2>About Us</h2>
          <h1>
            Who <span>we</span> Are
          </h1>
          <p>
            The CIA (Cleaning Intelligence Agents) is a company that consists of
            Highly skilled Professional cleaning agents whose purpose is to
            serve the Nation by keeping it Clean and Healthy for the benefit of
            every individual, community and entity for generations to come.
          </p>
          {/* <button className="secondary-btn"> <a href="/about">
            Explore More <i className="fas fa-long-arrow-alt-right"></i></a>
          </button> */}
        </div>
        <div className="row image">
          <img src={about2} alt="" />
          {/* <div className="control-btn">
            <button className="prev">
              <i className="fas fa-play"></i>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AboutCard;
