import React from "react";
import Mapping from "../../Common/Mapping/Mapping";

const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13233.3398033579!2d18.46262728586734!3d-33.98392934734124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc42c52081a7cb%3A0x79fbdc90a0e82eac!2sClaremont%2C%20Cape%20Town%2C%207708!5e0!3m2!1sen!2sza!4v1680864629719!5m2!1sen!2sza"
        width="100%"
        height="450"
        style={{ border: 0, marginTop: 20, borderRadius: "10px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <br />
      <br />
      <br />

      {/* <Mapping /> */}
      <br />
      <br />
      <br />
    </div>
  );
};

export default Map;
