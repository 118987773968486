import React from "react";
import "./About.css";
import about2 from "../../Assets/Img/AboutUs/3.jpg";

const AboutCard2 = () => {
  return (
    <section >
      <div className="aboutCard mtop flex_space">
        <div className="row row1">
          <h1>
            Our <span>Mission</span>
          </h1>
          <p><p>
           <h2>Our mission is simple but crutial:</h2></p>
           <br/>
           To contribute in the Increase of the Nation's life-expectancy.
           <br/>
           Keeping the nation clean and healthy by being the first-line of defense against any and all types of dirt. In all forms, macro, micro, liquid, chemical or solid, we make them all disapear. Leaving a space that's freshly-scented and welcoming.
           
          </p>
          {/* <button className="secondary-btn"> <a href="/about">
            Explore More <i className="fas fa-long-arrow-alt-right"></i></a>
          </button> */}
        </div>
        <div className="row image">
          <img src={about2} alt="" />
          {/* <div className="control-btn">
            <button className="prev">
              <i className="fas fa-play"></i>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AboutCard2;
